<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-4">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require(`@/assets/images/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              max-height="150px"
              max-width="200px"
              alt="Regent Renewables"
              class="ms-8"
              contain
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-0">
            Log In
          </p>
          <alert :text="alertText" :type="alertType" class="mt-4"></alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form v-model="formValid">
            <v-text-field
              v-model="email"
              outlined
              rounded
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
              :rules="[emailValidator]"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              rounded
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              :rules="[passwordValidator]"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="javascript:void(0)"
                class="mt-1"
              >
                Forgot Password?
              </a>
            </div>

            <v-btn
              block
              rounded
              color="primary"
              class="mt-6"
              :loading="authenticating"
              :disabled="!formValid"
              @click="login"
            >
              Log In
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{name:'register'}">
            Create an account
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLinks"
            :key="link.icon"
            icon
            large
            class="ms-4"
            @click="$auth.loginWithRedirect({ connection: link.connection })"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background image  -->
    <img
      class="auth-bg"
      :src="require('@/assets/images/background.jpg')"
    >
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Alert from '@/components/Alert.vue'

export default {
  components: { Alert },
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLinks = [
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
        connection: 'google-oauth2',
      },
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
        connection: 'facebook',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
        connection: 'twitter',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
        connection: 'github',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLinks,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      user: undefined,
      formValid: false,
      authenticating: false,
      alertText: undefined,
      alertType: undefined,
    }
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      this.$store.commit('alertType', 'info')
      this.$store.commit('alertText', 'You are already logged in')
      this.$router.push('/')
    }
  },
  methods: {
    emailValidator() {
      return this.email !== '' || 'Email is required'
    },
    passwordValidator() {
      return this.password !== '' || 'Password is required'
    },
    login() {
      this.authenticating = true
      this.alertText = undefined

      this.$auth.loginWithCredentials({
        username: this.email,
        password: this.password,
      }, () => {
        this.authenticating = false

        if (this.$auth.error) {
          this.alertText = this.$auth.error.description
          this.alertType = 'error'
        } else {
          this.$router.push('/')
        }
      })
    },
    loginWithGoogle() {
      this.$auth.loginWithRedirect({ connection: 'google-oauth2' })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
